<template>
  <collective-detail
    breadcrumb-route="events"
    item-type="events"
    item-type-single="event"
    custom-item-type="membership-event"
  />
</template>

<script>
import CollectiveDetail from '@/views/apps/collectives/components/CollectiveDetail.vue';

export default {
  name: 'MembershipEventDetails',
  components: {
    CollectiveDetail,
  },
};
</script>

<style>

</style>
